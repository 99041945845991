import React from 'react';
import Summary from '../components/Summary';
import Trade from '../components/Trade';
import {projectName} from '../data/API';

class Trades extends React.Component {
  componentDidMount(){
    document.title = `Trades | ${projectName} Scan`;
  }

  render() {
    return (
      <>
      <Summary />
      <div className="summary-div" style={{marginTop: "10px"}}>
        <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
          <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Sold</strong>
          <hr/>
          <Trade total={20} />
        </div>
      </div>
      </>
    )
  }
}

export default Trades;