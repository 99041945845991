import React from 'react';
import '../assets/css/styles.css';
import "react-circular-progressbar/dist/styles.css";
import { connect } from 'react-redux';
import { getOrder, getTrade, getTransfer, getCollection, resetStatusOrder, resetStatusTrade, resetStatusTransfer, resetStatusCollection } from '../redux/actions/getData';

class Summary extends React.Component {
  componentDidMount() {
    if(window.location.pathname === "/orders" || window.location.pathname === "/"){
      this.props.getOrder(window.location.pathname === "/orders" ? 20 : 5);
    }
    if(window.location.pathname === "/trades" || window.location.pathname === "/"){
      this.props.getTrade(window.location.pathname === "/trades" ? 20 : 5);
    }
    if(window.location.pathname === "/transfers" || window.location.pathname === "/"){
      this.props.getTransfer(window.location.pathname === "/transfers" ? 20 : 5);
    }
    setInterval(() => {
      if(window.location.pathname === "/orders" || window.location.pathname === "/"){
        if(this.props.fullData.statusCodeOrder) {
          this.props.resetStatusOrder();
          this.props.getOrder(window.location.pathname === "/orders" ? 20 : 5);
        }
      }
      if(window.location.pathname === "/transfers" || window.location.pathname === "/"){
        if(this.props.fullData.statusCodeTransfer) {
          this.props.resetStatusTransfer();
          this.props.getTransfer(window.location.pathname === "/transfers" ? 20 : 5);
        }
      }
      if(window.location.pathname === "/trades" || window.location.pathname === "/"){
        if(this.props.fullData.statusCodeTrade) {
          this.props.resetStatusTrade();
          this.props.getTrade(window.location.pathname === "/trades" ? 20 : 5);
        }
      }
    }, 6000);
  }
  render(){
    return(
      <>
      {/* <div className={this.props.home ? "summary-div" : "summary-div hide-mobile"}>
        <div className="summary shadow">
          <div className="row">
            <div className="col-md-3">
              <div className="title-div hr-right">
                <div className="title-icon">
                  <i className="bi bi-arrow-left-right"></i>
                </div>
                <div className="title">
                  <p>Total Order</p>
                </div>
                <div className="title-value">
                  {
                    this.props.fullData.latestOrder.length > 0 ?
                      <p>{parseInt(this.props.fullData.latestOrder[0].order_id).toLocaleString()}</p>
                    : <p>0</p>
                  }
                </div>
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getOrder, 
  getTrade, 
  getTransfer, 
  getCollection, 
  resetStatusOrder, 
  resetStatusTrade, 
  resetStatusTransfer, 
  resetStatusCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);