const init_state = {
  statusCodeOrder: '',
  statusCodeTrade: '',
  statusCodeTransfer: '',
  statusCodeCollection: '',
  statusCodeToken: '',
  statusCodeInventory: '',
  latestOrder: [],
  latestTrade: [],
  latestTransfer: [],
  latestCollection: [],
  latestInventory: [],
  nextCursor: "",
  prevCursor: "",
  dataRemaining: 0,
  tokenList: [],
  totalOrder: 0,
  totalTrade: 0,
  totalTransfer: 0,
  totalCOllocation: 0,
  pathName: "",
}

const reducer = (state = init_state, action) =>  {
  switch (action.type) {
    case "GET_PATH_NAME":
      return {...state, pathName: action.data}
    case "GET_ORDER":
      return {...state, statusCodeOrder: action.status, latestOrder: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
    case "GET_TRADE":
      return {...state, statusCodeTrade: action.status, latestTrade: action.data};
    case "GET_TRANSFER":
      return {...state, statusCodeTransfer: action.status, latestTransfer: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
    case "GET_COLLECTION":
      return {...state, statusCodeCollection: action.status, latestCollection: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
    case "GET_INVENTORY":
      return {...state, statusCodeInventory: action.status, latestInventory: action.data.data, nextCursor: action.data.nextCursor, dataRemaining: action.data.remaining};
    case "RESET_STATUS_ORDER":
      return {...state, statusCodeBlock: action.status}
    case "RESET_STATUS_TRADE":
      return {...state, statusCodeTrade: action.status}
    case "RESET_STATUS_TRANSFER":
      return {...state, statusCodeTransfer: action.status}
    case "RESET_STATUS_COLLECTION":
      return {...state, statusCodeCollection: action.status, latestCollection: []}
    case "RESET_STATUS_TOKEN":
      return {...state, statusCodeToken: action.status}
    default:
      return state;
  }
}

export default reducer;