import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import eth from '../ethereum.png';
import { getPathName } from '../redux/actions/getData';

class Trade extends React.Component {
  componentDidMount() {
    setInterval(() => {
      const timestampData = document.getElementsByClassName('timestampSaver');
      const stopwatchData = document.getElementsByClassName('stopwatch')
      const dateNow = new Date().getTime();
      var initLoop = 0;
      while (initLoop < timestampData.length) {
        var finalTimestamp = parseInt((dateNow-timestampData[initLoop].innerHTML)/1000);
        if (finalTimestamp > 60){
          finalTimestamp = parseInt(finalTimestamp/60)+"m";
        }else if(finalTimestamp > 3600){
          finalTimestamp = parseInt(finalTimestamp/3600)+"h";
        }else{
          finalTimestamp = finalTimestamp+"s";
        }
        stopwatchData[initLoop].innerHTML = finalTimestamp;
        initLoop++
      }
    }, 1000);
  }

  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    const arrResult = [];
    const orders = this.props.orderData;
    var totalData = 0;
    const dateNow = new Date();
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-4 col-6"
    var colClass3 = "col-md-6 col-6"
    var colClass4 = "col-md-6 col-6"
    if(!grid){
      colClass1 = "col-md-2 col-6"
      colClass2 = "col-md-2 col-6"
      colClass3 = "col-md-2 col-6"
      colClass4 = "col-md-2 col-6"
    }
    if(orders.length > 0) {
      while (totalData < numData) {
        const orderTimestamp = Date.parse(orders[totalData].timestamp);
        var orderDate = parseInt((dateNow-orderTimestamp)/1000);
        if(orderDate > 60){
          orderDate = parseInt(orderDate/60)+"m";
        }else if(orderDate > 3600){
          orderDate = parseInt(orderDate/3600)+"h";
        }else{
          orderDate = orderDate+"s"
        }
        arrResult.push(
          <a href={"/tx/"+orders[totalData].transaction_id}>
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><b>{parseInt(orders[totalData].transaction_id).toLocaleString()}</b></p>
                </div>
                <div className={colClass1}>
                  <p><b className="stopwatch">{orderDate}</b><u style={{"display": "none"}} className="timestampSaver">{orderTimestamp}</u> ago</p>
                </div>
                <div className={colClass2}>
                {
                    orders[totalData].to.type === "ERC721" ?
                      <p className="text-hash"><i className="bi bi-arrow-right text-success"></i> <img src={orders[totalData].to.image_url} alt={orders[totalData].to.token_name} className="image-validator" /><b>{orders[totalData].to.quantity}</b> {orders[totalData].to.token_name}</p>
                      : <p className="text-hash"><i className="bi bi-arrow-right text-success"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].to.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                  }
                </div>
                <div className={colClass2}>
                  {
                    orders[totalData].from.type === "ERC721" ?
                      <p className="text-hash"><i className="bi bi-arrow-left text-primary"></i> <img src={orders[totalData].from.image_url} alt={orders[totalData].from.token_name} className="image-validator" /><b>{orders[totalData].from.quantity}</b> {orders[totalData].from.token_name}</p>
                      : <p className="text-hash"><i className="bi bi-arrow-left text-primary"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].from.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                  }
                </div>
                <div className={colClass3}>
                  <a href={"/address/"+orders[totalData].from.user}><p className="text-hash"><i className="bi bi-arrow-right text-primary"></i> <i className="bi bi-person"></i> <b>{orders[totalData].from.user}</b></p></a>
                </div>
                <div className={colClass4}>
                  <a href={"/address/"+orders[totalData].to.user}><p className="text-hash"><i className="bi bi-arrow-left text-success"></i> <i className="bi bi-person"></i> <b>{orders[totalData].to.user}</b></p></a>
                </div>
              </div>
            </div>
            <hr style={{marginTop: "0px"}} />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }
  render() {
    return (
      <div>
        {
          (this.props.orderData).length > 0 ?
            this.loopData(this.props.total, this.props.grid)
          :
          <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orderData: state.user.latestTrade,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trade);