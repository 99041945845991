import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPathName } from '../redux/actions/getData';
import eth from '../ethereum.png';

class Order extends React.Component {
  componentDidMount() {
    setInterval(() => {
      const timestampData = document.getElementsByClassName('timestampSaver');
      const stopwatchData = document.getElementsByClassName('stopwatch')
      const dateNow = new Date().getTime();
      var initLoop = 0;
      while (initLoop < timestampData.length) {
        var finalTimestamp = parseInt((dateNow-timestampData[initLoop].innerHTML)/1000);
        if (finalTimestamp > 60){
          finalTimestamp = parseInt(finalTimestamp/60)+"m";
        }else if(finalTimestamp > 3600){
          finalTimestamp = parseInt(finalTimestamp/3600)+"h";
        }else{
          finalTimestamp = finalTimestamp+"s";
        }
        stopwatchData[initLoop].innerHTML = finalTimestamp;
        initLoop++
      }
    }, 1000);
  }

  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    const arrResult = [];
    const orders = this.props.orderData;
    var totalData = 0;
    const dateNow = new Date();
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-4 col-6"
    var colClass3 = "col-md-4 col-6"
    var colClass4 = "col-md-8 col-6"
    if(!grid){
      colClass1 = "col-md-2 col-6"
      colClass2 = "col-md-2 col-6"
      colClass3 = "col-md-2 col-6"
      colClass4 = "col-md-2 col-6"
    }
    if(orders.length > 0) {
      while (totalData < numData) {
        const orderTimestamp = Date.parse(orders[totalData].timestamp);
        var orderDate = parseInt((dateNow-orderTimestamp)/1000);
        if(orderDate > 60){
          orderDate = parseInt(orderDate/60)+"m";
        }else if(orderDate > 3600){
          orderDate = parseInt(orderDate/3600)+"h";
        }else{
          orderDate = orderDate+"s"
        }
        arrResult.push(
          <a href={
            orders[totalData].sell.data.token_address ?
              "https://market.x.immutable.com/assets/"+orders[totalData].sell.data.token_address+"/"+orders[totalData].sell.data.token_id
            : "https://market.x.immutable.com/assets/"+orders[totalData].buy.data.token_address+"/"+orders[totalData].buy.data.token_id
          } rel="noreferrer" target="_blank">
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><b>{parseInt(orders[totalData].order_id).toLocaleString()}</b></p>
                </div>
                <div className={colClass1}>
                  <p><b className="stopwatch">{orderDate}</b><u style={{"display": "none"}} className="timestampSaver">{orderTimestamp}</u> ago</p>
                </div>
                <div className={colClass2}>
                  {
                    orders[totalData].buy.type === "ERC721" ?
                    <a href={"https://market.x.immutable.com/assets/"+orders[totalData].buy.data.token_address+"/"+orders[totalData].buy.data.token_id} rel="noreferrer" target="_blank"><p className="text-hash"><i className="bi bi-arrow-down text-primary"></i> <img src={orders[totalData].buy.data.properties.image_url} alt={orders[totalData].buy.data.properties.name} className="image-validator" /><b>{orders[totalData].buy.data.quantity}</b> {orders[totalData].buy.data.properties.name}</p></a>
                    : 
                    orders[totalData].buy.type === "ETH" ?
                      <p className="text-hash"><i className="bi bi-arrow-down text-primary"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].buy.data.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                    : null
                  }
                </div>
                <div className={colClass2}>
                  {
                    orders[totalData].sell.type === "ERC721" ?
                      <a href={"https://market.x.immutable.com/assets/"+orders[totalData].sell.data.token_address+"/"+orders[totalData].sell.data.token_id} rel="noreferrer" target="_blank"><p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <img src={orders[totalData].sell.data.properties.image_url} alt={orders[totalData].sell.data.properties.name} className="image-validator" /><b>{orders[totalData].sell.data.quantity}</b> {orders[totalData].sell.data.properties.name}</p></a>
                    : <p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].sell.data.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                  }
                </div>
                <div className={colClass3}>
                  <p className="text-hash"><i className="bi bi-clock-history"></i> <b>{moment(orders[totalData].expiration_timestamp).format("DD/MM/YYYY")}</b></p>
                </div>
                <div className={colClass4}>
                  <a href={"/address/"+orders[totalData].user}><p className="text-hash"><i className="bi bi-person"></i> <b>{orders[totalData].user}</b></p></a>
                </div>
              </div>
            </div>
            <hr style={{marginTop: "0px"}} />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }
  render() {
    return (
      <div>
        {
          (this.props.orderData).length > 0 ?
            this.loopData(this.props.total, this.props.grid)
          :
          <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orderData: state.user.latestOrder,
    tokenData: state.user.tokenList,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);