import React from 'react';
import thecodes from '../thecodes_black.png';
import Axios from 'axios';
import { apiURL, projectName } from '../data/API';
import Label from '../components/Label';
import moment from 'moment';
import eth from '../ethereum.png';

class Transaction extends React.Component {
  state={}
  componentDidMount() {
    document.title = `Transaction #${parseInt(this.props.match.params.id).toLocaleString()} | ${projectName} Scan`;
    Axios.get(`${apiURL}/transfers/${this.props.match.params.id}`)
    .then(transfer => {
      if(transfer.data.token.type === "ERC721") {
        Axios.get(`${apiURL}/assets/${transfer.data.token.data.token_address}/${transfer.data.token.data.token_id}`)
        .then(asset => {
          this.setState({
            checked: true,
            id: this.props.match.params.id,
            status: transfer.data.status,
            timestamp: transfer.data.timestamp,
            from: transfer.data.user,
            to: transfer.data.receiver,
            type: transfer.data.token.type,
            transfer: true,
            token_id: transfer.data.token.data.token_id,
            token_address: transfer.data.token.data.token_address,
            quantity: transfer.data.token.data.quantity,
            token_name: asset.data.name,
            image_url: asset.data.image_url,
            collection_name: asset.data.collection.name,
            collection_image: asset.data.collection.icon_url
          })
        })
        .catch(err => {
          this.setState({
            checked: true
          })
        })
      }else if(transfer.data.token.type === "ERC20"){
        Axios.get(`${apiURL}/tokens/${transfer.data.token.data.token_address}`)
        .then(token => {
          this.setState({
            checked: true,
            id: this.props.match.params.id,
            status: transfer.data.status,
            timestamp: transfer.data.timestamp,
            from: transfer.data.user,
            to: transfer.data.receiver,
            type: transfer.data.token.type,
            transfer: true,
            token_address: transfer.data.token.data.token_address,
            quantity: transfer.data.token.data.quantity,
            token_name: token.data.name,
            image_url: token.data.image_url,
            symbol: token.data.symbol,
            decimal: token.data.decimals
          })
        })
        .catch(err => {
          this.setState({
            checked: true
          })
        })
      }else{
        this.setState({
          checked: true,
          id: this.props.match.params.id,
          status: transfer.data.status,
          timestamp: transfer.data.timestamp,
          from: transfer.data.user,
          to: transfer.data.receiver,
          type: transfer.data.token.type,
          transfer: false,
          token_address: transfer.data.token.data.token_address,
          quantity: transfer.data.token.data.quantity,
        })
      }
    })
    .catch(e => {
      Axios.get(`${apiURL}/trades/${this.props.match.params.id}`)
      .then(trade => {
        if(!trade.data.message) {
          Axios.get(`${apiURL}/orders/${trade.data.a.order_id}`)
          .then(order1 => {
            Axios.get(`${apiURL}/orders/${trade.data.b.order_id}`)
            .then(order2 => {
              this.setState({
                checked: true,
                id: this.props.match.params.id,
                status: trade.data.status,
                timestamp: trade.data.timestamp,
                from: order1.data.user,
                to: order2.data.user,
                type: "ERC721",
                quantity: order1.data.sell.data.quantity,
                token_id: order1.data.buy.data.token_id,
                token_address: order1.data.buy.data.token_address,
                token_name: order1.data.buy.data.properties.name,
                image_url: order1.data.buy.data.properties.image_url,
                collection_name: order1.data.buy.data.properties.collection.name,
                collection_image: order1.data.buy.data.properties.collection.icon_url
              })
            })
            .catch(err => {
              this.setState({
                checked: true
              })
            })
          })
          .catch(err => {
            this.setState({
              checked: true
            })
          })
        }
      })
      .catch(e => {
        Axios.get(`${apiURL}/deposits/${this.props.match.params.id}`)
        .then(deposit => {
          if(deposit.data.token.type === "ERC721") {
            Axios.get(`${apiURL}/assets/${deposit.data.token.data.token_address}/${deposit.data.token.data.token_id}`)
            .then(asset => {
              this.setState({
                checked: true,
                id: this.props.match.params.id,
                status: deposit.data.status,
                timestamp: deposit.data.timestamp,
                from: deposit.data.user,
                to: deposit.data.user,
                type: deposit.data.token.type,
                transfer: false,
                deposit: true,
                token_id: deposit.data.token.data.token_id,
                token_address: deposit.data.token.data.token_address,
                quantity: deposit.data.token.data.quantity,
                token_name: asset.data.name,
                image_url: asset.data.image_url,
                collection_name: asset.data.collection.name,
                collection_image: asset.data.collection.icon_url
              })
            })
            .catch(err => {
              this.setState({
                checked: true
              })
            })
          }else if(deposit.data.token.type === "ERC20"){
            Axios.get(`${apiURL}/tokens/${deposit.data.token.data.token_address}`)
            .then(token => {
              this.setState({
                checked: true,
                id: this.props.match.params.id,
                status: deposit.data.status,
                timestamp: deposit.data.timestamp,
                from: deposit.data.user,
                to: deposit.data.user,
                type: deposit.data.token.type,
                transfer: false,
                deposit: true,
                token_address: deposit.data.token.data.token_address,
                quantity: deposit.data.token.data.quantity,
                token_name: token.data.name,
                image_url: token.data.image_url,
                symbol: token.data.symbol,
                decimal: token.data.decimals
              })
            })
          }else{
            this.setState({
              checked: true,
              id: this.props.match.params.id,
              status: deposit.data.status,
              timestamp: deposit.data.timestamp,
              from: deposit.data.user,
              to: deposit.data.user,
              type: deposit.data.token.type,
              transfer: false,
              deposit: true,
              token_address: deposit.data.token.data.token_address,
              quantity: deposit.data.token.data.quantity,
            })
          }
        })
        .catch(e => {
          Axios.get(`${apiURL}/withdrawals/${this.props.match.params.id}`)
          .then(withdraw => {
            if(withdraw.data.token.type === "ERC721") {
              Axios.get(`${apiURL}/assets/${withdraw.data.token.data.token_address}/${withdraw.data.token.data.token_id}`)
              .then(asset => {
                this.setState({
                  checked: true,
                  id: this.props.match.params.id,
                  status: withdraw.data.rollup_status,
                  timestamp: withdraw.data.timestamp,
                  from: withdraw.data.sender,
                  to: withdraw.data.sender,
                  type: withdraw.data.token.type,
                  transfer: false,
                  withdraw: true,
                  token_id: withdraw.data.token.data.token_id,
                  token_address: withdraw.data.token.data.token_address,
                  quantity: withdraw.data.token.data.quantity,
                  token_name: asset.data.name,
                  image_url: asset.data.image_url,
                  collection_name: asset.data.collection.name,
                  collection_image: asset.data.collection.icon_url
                })
              })
              .catch(err => {
                this.setState({
                  checked: true
                })
              })
            }else if(withdraw.data.token.type === "ERC20"){
              Axios.get(`${apiURL}/tokens/${withdraw.data.token.data.token_address}`)
              .then(token => {
                this.setState({
                  checked: true,
                  id: this.props.match.params.id,
                  status: withdraw.data.rollup_status,
                  timestamp: withdraw.data.timestamp,
                  from: withdraw.data.sender,
                  to: withdraw.data.sender,
                  type: withdraw.data.token.type,
                  transfer: false,
                  withdraw: true,
                  token_address: withdraw.data.token.data.token_address,
                  quantity: withdraw.data.token.data.quantity,
                  token_name: token.data.name,
                  image_url: token.data.image_url,
                  symbol: token.data.symbol,
                  decimal: token.data.decimals
                })
              })
              .catch(err => {
                this.setState({
                  checked: true
                })
              })
            }else{
              this.setState({
                checked: true,
                id: this.props.match.params.id,
                status: withdraw.data.rollup_status,
                timestamp: withdraw.data.timestamp,
                from: withdraw.data.sender,
                to: withdraw.data.sender,
                type: withdraw.data.token.type,
                transfer: false,
                withdraw: true,
                token_address: withdraw.data.token.data.token_address,
                quantity: withdraw.data.token.data.quantity,
              })
            }
          })
          .catch(err => {
            this.setState({
              checked: true
            })
          })
        })
      })
    })
  }

  checkImage(e){
    e.target.src = thecodes;
    e.target.style= "filter: grayscale(100%)"
  }

  render(){
    return (
      <div className="summary-div" style={{marginTop: "10px"}}>
        <div className="summary shadow" style={
          this.state.checked && this.state.status ?
          {borderRadius: "4px", padding: "15px"}
          : {borderRadius: "4px", minHeight: "622px", padding: "15px"}
        }>
          {
            this.state.checked && this.state.status ?
            <>
              {
                this.state.type === "ERC721" && this.state.transfer ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC721 Transfer Detail</strong>
                : this.state.type === "ERC721" && this.state.deposit ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC721 Deposit Detail</strong>
                : this.state.type === "ERC721" && this.state.withdraw ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC721 Withdraw Detail</strong>
                : this.state.type === "ERC721" && !this.state.transfer ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC721 Trade Detail</strong>
                : this.state.type === "ERC20" && this.state.transfer ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC20 Transfer Detail</strong>
                : this.state.type === "ERC20" && this.state.deposit ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC20 Deposit Detail</strong>
                : this.state.type === "ERC20" && this.state.withdraw ?
                  <strong style={{fontSize: "17px", marginLeft: "0"}}>ERC20 Withdraw Detail</strong>
                : <strong style={{fontSize: "17px", marginLeft: "0"}}>ETH Transaction Detail</strong>
              }
              <hr/>
              {
                this.state.type === "ERC721" ?
                  <div className="row">
                    <div className="col-md-4">
                      <img style={{width: "100%", height: "auto"}} src={this.state.image_url} alt={this.state.token_name} />
                    </div>
                    <div className="col-md-8 nft-detail-container">
                      <a href={`/address/${this.state.token_address}`}><p className="collection-name"><img src={this.state.collection_image} alt={this.state.collection_name} className="image-validator" />{this.state.collection_name}</p></a>
                      <h4>{this.state.token_name}</h4>
                      <Label label="Tx ID" value={<b>{parseInt(this.props.match.params.id).toLocaleString()}</b>} />
                      <Label label="Status" value={<b style={{textTransform: "capitalize"}}>{this.state.status}</b>} />
                      <Label label="Date Time" value={<>{moment(this.state.timestamp).format("DD/MM/YYYY h:m:s")} <b>({moment(this.state.timestamp).fromNow()})</b></>} />
                      {
                        this.state.deposit ?
                        <>
                        <Label label="From" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                        <Label label="To" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                        </>
                        : this.state.withdraw ?
                        <>
                        <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                        <Label label="To" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                        </>
                        :
                        <>
                        <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                        <Label label="To" value={this.state.to} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.to)}}></i>} linkTo={`/address/${this.state.to}`} tooltip link />
                        </>
                      }
                      {
                        this.state.transfer ?
                          <Label label="Amount" value={this.state.quantity} />
                        : <Label label="Price" value={<><img src={eth} alt="ETH" className="image-validator" /> {(parseInt(this.state.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH</>} />
                      }
                    </div>
                  </div>
                : this.state.type === "ERC20" ?
                <>
                  <Label label="Tx ID" value={<b>{parseInt(this.props.match.params.id).toLocaleString()}</b>} />
                  <Label label="Status" value={<b style={{textTransform: "capitalize"}}>{this.state.status}</b>} />
                  <Label label="Date Time" value={<>{moment(this.state.timestamp).format("DD/MM/YYYY h:m:s")} <b>({moment(this.state.timestamp).fromNow()})</b></>} />
                  {
                    this.state.deposit ?
                    <>
                      <Label label="From" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                      <Label label="To" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                    </>
                    : this.state.withdraw ?
                    <>
                      <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                      <Label label="To" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                    </>
                    :
                    <>
                      <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                      <Label label="To" value={this.state.to} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.to)}}></i>} linkTo={`/address/${this.state.to}`} tooltip link />
                    </>
                  }
                  <Label label="Amount" value={<span><img src={this.state.image_url} alt={this.state.symbol} className="image-validator" /> {(parseInt(this.state.quantity)/10**this.state.decimal).toLocaleString(undefined, {maximumFractionDigits: 8})}</span>} tooltipValue={<a href={`/address/${this.state.token_address}`}> {this.state.symbol}</a>} tooltipLabel={<span>{this.state.token_address}</span>} tooltip />
                </>
                : 
                <>
                  <Label label="Tx ID" value={<b>{parseInt(this.props.match.params.id).toLocaleString()}</b>} />
                  <Label label="Status" value={<b style={{textTransform: "capitalize"}}>{this.state.status}</b>} />
                  <Label label="Date Time" value={<>{moment(this.state.timestamp).format("DD/MM/YYYY h:m:s")} <b>({moment(this.state.timestamp).fromNow()})</b></>} />
                  {
                    this.state.deposit ?
                    <>
                      <Label label="From" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                      <Label label="To" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                    </>
                    : this.state.withdraw ?
                    <>
                      <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                      <Label label="To" value={<a href={`https://etherscan.io/address/${this.state.from}`} className="content-table-link" rel="noreferrer" target="_blank">{this.state.from}</a>} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} tooltip />
                    </>
                    :
                    <>
                      <Label label="From" value={this.state.from} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.from)}}></i>} linkTo={`/address/${this.state.from}`} tooltip link />
                      <Label label="To" value={this.state.to} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.to)}}></i>} linkTo={`/address/${this.state.to}`} tooltip link />
                    </>
                  }
                  <Label label="Amount" value={<span><img src={eth} alt="ETH" className="image-validator" /> {(parseInt(this.state.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH</span>} />
                </>
              }
            </>
            : this.state.checked && !this.state.status ?
              null
            :
            <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Transaction;