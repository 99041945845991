import React from 'react';
import { connect } from 'react-redux';
import Summary from '../components/Summary';
import { getPathName, getCollection, resetStatusCollection } from '../redux/actions/getData';
import thecodes from '../thecodes_black.png';
import {projectName} from '../data/API';

class Collections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevCursor: [""],
      page: 1
    }

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount(){
    document.title = `Collections | ${projectName} Scan`;
    this.props.getCollection();
  }

  checkImage(e){
    e.target.src = thecodes;
    e.target.style= "filter: grayscale(100%)"
  }

  prevPage () {
    this.setState({
      page: this.state.page - 1
    });
    this.props.resetStatusCollection();
    this.props.getCollection(this.state.prevCursor[this.state.page - 2]);
  }

  nextPage(){
    if(this.state.prevCursor.length <= this.state.page) {
      this.setState({
        prevCursor: [...this.state.prevCursor, this.props.nextCursor],
        page: this.state.page + 1
      });
    }else{
      this.setState({
        page: this.state.page + 1
      })
    }
    this.props.resetStatusCollection();
    this.props.getCollection(this.props.nextCursor)
  }

  render() {
    return (
      <>
      <Summary />
      {
        <div className="summary-div" style={{marginTop: "10px"}}>
          <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
            {
              this.props.collections.length > 0 ?
              <>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Collections</strong>
                <hr/>
                {
                  this.props.collections.map(collection => {
                    return(
                      <div className="collection-list">
                        <a href={`https://market.x.immutable.com/assets?collection=${collection.address}`} rel="noreferrer" target="_blank" ><img src={collection.icon_url} onError={this.checkImage} alt={collection.name} /></a>
                      </div>
                    )
                  })
                }
                {
                  this.props.collectionRemaining > 0 || this.state.page > 1 ?
                    <div className="container-pagination">
                    {
                      this.state.page > 1 ?
                      <button className="btn" onClick={this.prevPage}><i className="bi bi-chevron-left"></i> Previous</button>
                      : <button className="btn" disabled><i className="bi bi-chevron-left"></i> Previous</button>
                    }
                    {
                      this.props.collectionRemaining > 0 ?
                      <button className="btn" onClick={this.nextPage}>Next <i className="bi bi-chevron-right"></i></button>
                      : <button className="btn" disabled>Next <i className="bi bi-chevron-right"></i></button>
                    }
                    </div>
                  : null
                }
              </>
              : <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
            }
          </div>
        </div>
      }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    collections: state.user.latestCollection,
    nextCursor: state.user.nextCursor,
    collectionRemaining: state.user.dataRemaining,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
  getCollection,
  resetStatusCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Collections);