import React from 'react';
import '../assets/css/styles.css';
import thecodes_white from "../thecodes_white.png";
import { getPathName } from '../redux/actions/getData';
import { connect } from 'react-redux';

class Header extends React.Component {
  state = {}
  componentWillMount() {
    this.props.getPathName(window.location.pathname);
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  searchHandle(searchString){
    if(searchString){
      if(searchString.length === 42){
        window.location.href = `/address/${searchString}`
      }else if(parseInt(searchString) > 0){
        window.location.href = `/tx/${searchString}`
      }
    }
  }
  render(){
    return(
      <div className="header-earlier">
        <div className="header-div">
          <div className="header shadow">
            <div className="header-left">
              <div className="network-using">
                <img src="https://foundation.ki/static/media/ki_foundation_mobile.2f41efa2.png" alt="KiChain Explorer"/>
              </div>
              <a href="/"><img src={thecodes_white} alt="TheCodes Explorer" className="thecodes-header" /></a>
              <div className="header-menu-show">
                <i className="bi bi-text-left"></i>
              </div>
              <div className="header-right">
                <div className="search-header">
                  <input type="text" placeholder="Search by ID, Address or Hash" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
                  <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
                </div>
                <a className="header-link" href="/collections">
                  <div className="header-menu" style={this.props.pathName === "/collections" ? {color: "yellow"} : {color: "white"}}>
                    Collections
                  </div>
                </a>
                <a className="header-link" href="/orders">
                  <div className="header-menu" style={this.props.pathName === "/orders" ? {color: "yellow"} : {color: "white"}}>
                    Orders
                  </div>
                </a>
                <a className="header-link" href="/trades">
                  <div className="header-menu" style={this.props.pathName === "/trades" ? {color: "yellow"} : {color: "white"}}>
                    Trades
                  </div>
                </a>
                <a className="header-link" href="/transfers">
                  <div className="header-menu" style={this.props.pathName === "/transfers" ? {color: "yellow"} : {color: "white"}}>
                    Transfers
                  </div>
                </a>
              </div>
            </div>
            <div className="header-search">
              <input type="text" placeholder="Search by ID, Address or Hash" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
              <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);