import React from 'react';
import Summary from '../components/Summary';
import Order from '../components/Order';
import Trade from '../components/Trade';
import Transfer from '../components/Transfer';
import { projectName } from '../data/API';

class Home extends React.Component {
  componentDidMount(){
    document.title = `${projectName} Scan`;
  }
  render(){
    return(
      <>
        <Summary home />
        <div className="summary-div" style={{marginTop: "10px"}}>
          <div className="row">
            <div className="col-md-6">
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Offer</strong>
                <hr/>
                <Order total={5} grid />
              </div>
            </div>
            <div className="col-md-6">
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Sold</strong>
                <hr/>
                <Trade total={5} grid />
              </div>
            </div>
            <div className="col-md-12">
              <div className="summary shadow" style={{borderRadius: "4px", paddingTop: "12px"}}>
                <strong style={{fontSize: "17px", marginLeft: "6px"}}>Latest Transfer</strong>
                <hr/>
                <Transfer total={5} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Home;