import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from './components/Header';
import Home from './pages/Home';
import Collections from './pages/Collections';
import Transfers from './pages/Transfers';
import Orders from './pages/Orders';
import Trades from './pages/Trades';
import Transaction from './pages/Transaction';
import Address from './pages/Address';
import Token from './pages/Token';
import Collection from './pages/Collection';
import Footer from './components/Footer';

function App() {
  return (
    
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact component={Address} path="/address/:address"/>
        <Route exact component={Collections} path="/collections"/>
        <Route exact component={Trades} path="/trades"/>
        <Route exact component={Orders} path="/orders"/>
        <Route exact component={Transfers} path="/transfers"/>
        <Route exact component={Transaction} path="/tx/:id"/>
        <Route exact component={Token} path="/token/:address"/>
        <Route exact component={Collection} path="/collection/:address"/>
        <Route exact component={Home} path="/"/>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
