import Axios from 'axios';
import { apiURL } from '../../data/API';

export const getOrder = (limit, address, cursor) => {
  var additional = "";
  if(address) {
    additional = `&user=${address}`
  }
  if(cursor){
    additional = `${additional}&cursor=${cursor}`
  }
  return (dispatch) => {
    Axios.get(`${apiURL}/orders?page_size=${limit}&status=active${additional}`)
    .then((response) => {
      if(response.data.result){
        const ordersData = response.data;
        dispatch({
          type: 'GET_ORDER',
          status: response.status,
          data: {
            data: ordersData.result,
            nextCursor: ordersData.cursor,
            remaining: ordersData.remaining
          }
        })
      }
    })
    .catch((e) => {
      dispatch({
        type: 'RESET_STATUS_ORDER',
        status: "300"
      })
    })
  }
}
export const getTrade = (limit) => {
  if(!limit){
    limit = 20
  }
  return (dispatch) => {
    Axios.get(`${apiURL}/trades?page_size=${limit}`)
    .then((response) => {
      if(response.data.result){
        const tradesData = response.data;
        var orderDetail = [];
        tradesData.result.forEach(trade => {
          Axios.get(`${apiURL}/orders/${trade.a.order_id}`)
          .then(order1 => {
            Axios.get(`${apiURL}/orders/${trade.b.order_id}`)
            .then(order2 => {
              orderDetail.push({
                transaction_id: trade.transaction_id,
                timestamp: trade.timestamp,
                from : { 
                  user: order1.data.user,
                  type: order1.data.buy.type,
                  token_address: order1.data.buy.type === "ERC721" ? order1.data.buy.data.token_address : order1.data.buy.data.token_address,
                  quantity: order1.data.buy.type === "ERC721" ? order1.data.buy.data.quantity : order1.data.buy.data.quantity,
                  token_id: order1.data.buy.type === "ERC721" ? order1.data.buy.data.token_id : null,
                  token_name: order1.data.buy.type === "ERC721" ? order1.data.buy.data.properties.name : null,
                  image_url: order1.data.buy.type === "ERC721" ? order1.data.buy.data.properties.image_url : null,
                },
                to : { 
                  user: order2.data.user,
                  type: order2.data.buy.type,
                  token_address: order2.data.buy.type === "ERC721" ? order2.data.buy.data.token_address : order2.data.buy.data.token_address,
                  quantity: order2.data.buy.type === "ERC721" ? order2.data.buy.data.quantity : order2.data.buy.data.quantity,
                  token_id: order2.data.buy.type === "ERC721" ? order2.data.buy.data.token_id : null,
                  token_name: order2.data.buy.type === "ERC721" ? order2.data.buy.data.properties.name : null,
                  image_url: order2.data.buy.type === "ERC721" ? order2.data.buy.data.properties.image_url : null,
                }
              })
              if(orderDetail.length === tradesData.result.length){
                orderDetail.sort(function(a, b) {
                  return a.transaction_id - b.transaction_id
                });
                dispatch({
                  type: 'GET_TRADE',
                  status: response.status,
                  data: orderDetail.reverse()
                })
              }
            })
          })
        })
      }
    })
    .catch((e) => {
      dispatch({
        type: 'RESET_STATUS_TRADE',
        status: "300"
      })
    })
  }
}
export const getTransfer = (limit, address, cursor, contract, type) => {
  if(!limit){
    limit = 20;
  }
  var additional = "";
  if(cursor){
    additional = `&cursor=${cursor}`
  }
  if(contract && type){
    additional = `${additional}&token_address=${contract}&token_type=${type}`
  }
  if(address){
    additional = `${additional}&user=${address}`
  }
  return (dispatch) => {
    Axios.get(`${apiURL}/transfers?page_size=${limit}${additional}`)
    .then((response) => {
      if(response.data.result){
        const transfersData = response.data;
        if(contract && type === "ERC20"){
          dispatch({
            type: 'GET_TRANSFER',
            status: response.status,
            data: {
              data: transfersData.result,
              nextCursor: transfersData.cursor,
              remaining: transfersData.remaining
            }
          })
        }else{
          var detailTransfer = [];
          transfersData.result.forEach(async(transfer) => {
            if(transfer.token.type === "ERC721"){
              const response = await Axios.get(`${apiURL}/assets/${transfer.token.data.token_address}/${transfer.token.data.token_id}`)
              if(response.data){
                detailTransfer.push({
                  transaction_id: transfer.transaction_id,
                  type: "ERC721",
                  from: transfer.user,
                  to: transfer.receiver,
                  timestamp: transfer.timestamp,
                  token_name: response.data.name,
                  token_id: transfer.token.data.token_id,
                  token_address: transfer.token.data.token_address,
                  quantity: transfer.token.data.quantity,
                  image_url: response.data.image_url,
                })
              }
            }else if(transfer.token.type === "ERC20"){
              const response = await Axios.get(`${apiURL}/tokens/${transfer.token.data.token_address}`)
              if(response.data){
                detailTransfer.push({
                  transaction_id: transfer.transaction_id,
                  from: transfer.user,
                  to: transfer.receiver,
                  type: "ERC20",
                  timestamp: transfer.timestamp,
                  token_address: transfer.token.data.token_address,
                  quantity: transfer.token.data.quantity,
                  decimal: response.data.decimals,
                  symbol: response.data.symbol,
                  image_url: response.data.image_url
                })
              }
            }else{
              detailTransfer.push({
                transaction_id: transfer.transaction_id,
                from: transfer.user,
                to: transfer.receiver,
                timestamp: transfer.timestamp,
                symbol: "ETH",
                token_address: transfer.token.data.token_address,
                quantity: transfer.token.data.quantity,
              })
            }
            
            if(detailTransfer.length === transfersData.result.length) {
              detailTransfer.sort(function(a, b) {
                return a.transaction_id - b.transaction_id
              });
              dispatch({
                type: 'GET_TRANSFER',
                status: response.status,
                data: {
                  data: detailTransfer.reverse(),
                  nextCursor: transfersData.cursor,
                  remaining: transfersData.remaining
                }
              })
            }
          })
        }
      }
    })
    .catch((e) => {
      dispatch({
        type: 'RESET_STATUS_TRANSFER',
        status: "300"
      })
    })
  }
}
export const getCollection = (cursor) => {
  var additional = "";
  if(cursor){
    additional = `&cursor=${cursor}`
  }
  return (dispatch) => {
    Axios.get(`${apiURL}/collections?page_size=100${additional}`)
    .then((response) => {
      if(response.data.result){
        const collectionsData = response.data;
        dispatch({
          type: 'GET_COLLECTION',
          status: response.status,
          data: {
            data: collectionsData.result,
            nextCursor: collectionsData.cursor,
            remaining: collectionsData.remaining
          },
        })
      }
    })
    .catch((e) => {
      dispatch({
        type: 'RESET_STATUS_TRANSFER',
        status: "300"
      })
    })
  }
}
export const getInventory = (limit, address, cursor) => {
  var additional = "";
  if(cursor){
    additional = `&cursor=${cursor}`
  }
  if(address){
    additional = `${additional}&user=${address}`
  }
  return (dispatch) => {
    Axios.get(`${apiURL}/assets?page_size=${limit}${additional}`)
    .then((response) => {
      if(response.data.result){
        const inventorysData = response.data;
        dispatch({
          type: 'GET_INVENTORY',
          status: response.status,
          data: {
            data: inventorysData.result,
            nextCursor: inventorysData.cursor,
            remaining: inventorysData.remaining
          },
        })
      }
    })
    .catch((e) => {
      dispatch({
        type: 'RESET_STATUS_TRANSFER',
        status: "300"
      })
    })
  }
}
export const resetStatusOrder = (status) => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_STATUS_BLOCK',
      status
    })
  }
}
export const resetStatusTrade = (status) => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_STATUS_TRADE',
      status
    })
  }
}
export const resetStatusTransfer = (status) => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_STATUS_TRANSFER',
      status
    })
  }
}
export const resetStatusCollection = (status) => {
  return async (dispatch) => {
    dispatch({
      type: 'RESET_STATUS_COLLECTION',
      status
    })
  }
}
export const getPathName = (pathName) => {
  return(dispatch) => {
    dispatch({
      type: 'GET_PATH_NAME',
      data: pathName
    })
  }
}