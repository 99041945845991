import React from 'react';
import { connect } from 'react-redux';
import eth from '../ethereum.png';
import Axios from 'axios';
import { projectName, apiURL } from '../data/API';
import Label from '../components/Label';
import { getTransfer, resetStatusTransfer, getOrder, resetStatusOrder, getInventory } from '../redux/actions/getData';
import moment from 'moment';

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevCursor: [""],
      page: 1,
      looking: "out",
      displayDropdown: false
    }

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount(){
    document.title = `Address ${this.props.match.params.address} | ${projectName} Scan`;
    this.checkToken();
    this.checkCollection();
    this.getToken();
    this.getNFT();
    this.props.getTransfer(10, this.props.match.params.address);
    this.setState({
      checkedTxs: true
    })
  }

  checkToken(){
    Axios.get(`${apiURL}/tokens/${this.props.match.params.address}`)
    .then(token => {
      this.setState({
        token: true,
        token_symbol: token.data.symbol,
        token_decimal: token.data.decimals,
        token_name: token.data.name,
        token_image: token.data.image_url,
      })
    })
    .catch(err => {
      this.setState({
        token: false
      })
    })
  }
  checkCollection() {
    Axios.get(`${apiURL}/collections/${this.props.match.params.address}`)
    .then(collection => {
      this.setState({
        collection: true,
        collection_name: collection.data.name,
        collection_image: collection.data.icon_url
      })
    })
    .catch(err => {
      this.setState({
        collection: false
      })
    })
  }
  getToken() {
    Axios.get(`https://api.x.immutable.com/v2/balances/${this.props.match.params.address}`)
    .then(token => {
      this.setState({
        checkedToken: true,
        tokens: token.data.result,
      })
      if(this.state.tokens.some(symbol => symbol.symbol === "ETH")){
        token.data.result.map(token => {
          return(
            token.symbol === "ETH" ?
              this.setState({
                checkedBalance: true,
                balance: token.balance,
                withdrawable: token.withdrawable
              })
            : null
          )
        })
      }else{
        this.setState({
          checkedBalance: true,
          balance: 0,
          withdrawable: 0
        })
      }
    })
    .catch(err => {
      this.setState({
        checkedToken: true,
      })
    })
  }
  getNFT() {
    Axios.get(`${apiURL}/assets?user=${this.props.match.params.address}`)
    .then(nft => {
      this.setState({
        checkedNFT: true,
        nft: nft.data.imx,
      })
    })
    .catch(err => {
      this.setState({
        checkedNFT: true,
      })
    })
  }

  prevPage () {
    this.setState({
      page: this.state.page - 1
    });
    if(this.state.looking === "out") {
      this.props.getTransfer(10, this.props.match.params.address, this.state.prevCursor[this.state.page - 2]);
    }else if(this.state.looking === "order") {
      this.props.getOrder(10, this.props.match.params.address, this.state.prevCursor[this.state.page - 2]);
    }else if(this.state.looking === "inventory") {
      this.props.getInventory(12, this.props.match.params.address, this.state.prevCursor[this.state.page - 2]);
    }
  }

  nextPage(){
    if(this.state.prevCursor.length <= this.state.page) {
      this.setState({
        prevCursor: [...this.state.prevCursor, this.props.nextCursor],
        page: this.state.page + 1
      });
    }else{
      this.setState({
        page: this.state.page + 1
      })
    }
    if(this.state.looking === "out") {
      this.props.getTransfer(10, this.props.match.params.address, this.props.nextCursor)
    }else if(this.state.looking === "order") {
      this.props.getOrder(10, this.props.match.params.address, this.props.nextCursor);
    }if(this.state.looking === "inventory") {
      this.props.getInventory(12, this.props.match.params.address, this.props.nextCursor);
    }
  }

  checkImage(e){
    e.target.src = eth;
    e.target.style= "filter: grayscale(100%)"
  }

  outData = () => {
    const arrResult = [];
    const transfers = this.props.transfers;
    var totalData = 0;
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-2 col-12"
    var colClass3 = "col-md-3 col-6"
    var colClass4 = "col-md-3 col-6"
    if(transfers.length > 0) {
      while (totalData < this.props.transfers.length) {
        arrResult.push(
          <a href={"/tx/"+transfers[totalData].transaction_id}>
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><b>{parseInt(transfers[totalData].transaction_id).toLocaleString()}</b></p>
                </div>
                <div className={colClass1}>
                  <p><b className="stopwatch">{moment(transfers[totalData].timestamp).fromNow()}</b></p>
                </div>
                <div className={colClass2}>
                  {
                    transfers[totalData].type === "ERC721" ?
                      <a href={"/address/"+transfers[totalData].token_address+"/"+transfers[totalData].token_id}><p className="text-hash"><img src={transfers[totalData].image_url} alt={transfers[totalData].symbol} className="image-validator" /><b>{transfers[totalData].quantity}</b> {transfers[totalData].token_name}</p></a>
                    : transfers[totalData].type === "ERC20" ?
                      <a href={"/address/"+transfers[totalData].token_address}><p className="text-hash"><img src={transfers[totalData].image_url} alt={transfers[totalData].token_name} className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**transfers[totalData].decimal).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> {transfers[totalData].symbol}</p></a>
                    : <p className="text-hash"><img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(transfers[totalData].quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                  }
                </div>
                <div className={colClass3}>
                  <a href={"/address/"+transfers[totalData].from} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <i className="bi bi-person"></i> <b>{transfers[totalData].from}</b></p></a>
                </div>
                <div className={colClass4}>
                  <a href={"/address/"+transfers[totalData].to} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-return-right text-primary"></i> <i className="bi bi-person"></i> <b>{transfers[totalData].to}</b></p></a>
                </div>
              </div>
            </div>
            <hr style={{marginTop: "0px"}} />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }
  inventoryData() {
    const arrResult = [];
    const inventories = this.props.inventories;
    var totalData = 0;
    if(inventories.length > 0) {
      while (totalData < inventories.length) {
        arrResult.push(
          <div className="col-md-2 col-6">
            <div class="card-container">
              <img src={inventories[totalData].image_url} alt={inventories[totalData].name} style={{width:"100%"}}/>
              <div class="container">
                <p>{inventories[totalData].name}</p>
                <p style={{marginTop:"-15px", fontSize:"13px"}}><a href={`/address/${inventories[totalData].token_address}`}><img src={inventories[totalData].collection.icon_url} alt={inventories[totalData].collection.name} className="image-validator" />{inventories[totalData].collection.name}</a></p>
              </div>
            </div>
          </div>
        );
        totalData++;
      }
    }
    return arrResult;
  }
  orderData() {
    const arrResult = [];
    const orders = this.props.orders;
    var totalData = 0;
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-2 col-6"
    var colClass3 = "col-md-2 col-6"
    var colClass4 = "col-md-2 col-6"
    if(orders.length > 0) {
      while (totalData < orders.length) {
        arrResult.push(
          <a href={
            orders[totalData].sell.data.token_address ?
              "https://market.x.immutable.com/assets/"+orders[totalData].sell.data.token_address+"/"+orders[totalData].sell.data.token_id
            : "https://market.x.immutable.com/assets/"+orders[totalData].buy.data.token_address+"/"+orders[totalData].buy.data.token_id
          } rel="noreferrer" target="_blank">
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><b>{parseInt(orders[totalData].order_id).toLocaleString()}</b></p>
                </div>
                <div className={colClass1}>
                  <p><b className="stopwatch">{moment(orders[totalData].timestamp).fromNow()}</b></p>
                </div>
                <div className={colClass2}>
                  {
                    orders[totalData].buy.type === "ERC721" ?
                    <a href={"https://market.x.immutable.com/assets/"+orders[totalData].buy.data.token_address+"/"+orders[totalData].buy.data.token_id} rel="noreferrer" target="_blank"><p className="text-hash"><i className="bi bi-arrow-down text-primary"></i> <img src={orders[totalData].buy.data.properties.image_url} alt={orders[totalData].buy.data.properties.name} className="image-validator" /><b>{orders[totalData].buy.data.quantity}</b> {orders[totalData].buy.data.properties.name}</p></a>
                    : 
                    orders[totalData].buy.type === "ETH" ?
                      <p className="text-hash"><i className="bi bi-arrow-down text-primary"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].buy.data.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                    : null
                  }
                </div>
                <div className={colClass2}>
                  {
                    orders[totalData].sell.type === "ERC721" ?
                      <a href={"https://market.x.immutable.com/assets/"+orders[totalData].sell.data.token_address+"/"+orders[totalData].sell.data.token_id} rel="noreferrer" target="_blank"><p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <img src={orders[totalData].sell.data.properties.image_url} alt={orders[totalData].sell.data.properties.name} className="image-validator" /><b>{orders[totalData].sell.data.quantity}</b> {orders[totalData].sell.data.properties.name}</p></a>
                    : <p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <img src={eth} alt="Ethereum" className="image-validator" /><b>{(parseInt(orders[totalData].sell.data.quantity)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})}</b> ETH</p>
                  }
                </div>
                <div className={colClass3}>
                  <p className="text-hash"><i className="bi bi-clock-history"></i> <b>{moment(orders[totalData].expiration_timestamp).format("DD/MM/YYYY")}</b></p>
                </div>
                <div className={colClass4}>
                  <a href={"/address/"+orders[totalData].user}><p className="text-hash"><i className="bi bi-person"></i> <b>{orders[totalData].user}</b></p></a>
                </div>
              </div>
            </div>
            <hr style={{marginTop: "0px"}} />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }

  changeLooking(looking){
    this.setState({
      looking: looking,
      prevCursor: [""],
      page: 1
    })
    if(looking === "out"){
      this.props.getTransfer(10, this.props.match.params.address)
    }else if(looking === "inventory"){
      this.props.getInventory(12, this.props.match.params.address)
    }else if(looking === "order"){
      this.props.getOrder(10, this.props.match.params.address)
    }
  }

  render() {
    return(
      <>
        <div className="summary-div" style={{marginTop: "10px"}}>
            {
              this.state.checkedBalance && this.state.checkedNFT && this.state.checkedToken ?
              <>
                <div className="row">
                  {
                    this.state.token || this.state.collection ?
                    <>
                      <div className="col-md-6">
                        <div className="summary shadow" style={{borderRadius: "4px", padding: "12px"}}>
                          <strong style={{fontSize: "17px", marginLeft: "0px"}} className="text-hash">Address Detail</strong>
                          <hr/>
                          <Label label="Address" value={this.props.match.params.address} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address)}}></i>} tooltip />
                          <Label label="Balance" value={<>{(parseInt(this.state.balance)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH (<span style={{fontSize:"13px"}}>Withdrawable {(parseInt(this.state.withdrawable)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH</span>)</>} />
                          { 
                          (this.state.tokens.some(symbol => symbol.symbol === "ETH") && this.state.tokens.length > 1) || (!this.state.tokens.some(symbol => symbol.symbol === "ETH") && this.state.tokens.length > 0) ? 
                          <div className="dropdown-earlier">
                            <button className="btn" onClick={() => this.setState({displayDropdown: !this.state.displayDropdown})}>
                              {this.state.tokens.some(symbol => symbol.symbol === "ETH") ? (this.state.tokens).length-1 : (this.state.tokens).length} Tokens <i className="bi bi-chevron-down"></i>
                            </button>
                            {
                              this.state.displayDropdown ?
                              <div className="dropdown-menu-token overflow-custom summary" aria-labelledby="dropdownMenuButton">
                                {
                                  (this.state.tokens).map(balance => {
                                    return (
                                      balance.symbol !== "ETH" ?
                                      <a href={`/token/${balance.symbol}`} className="dropdown-item" style={{"textTransform": "uppercase"}}>
                                        {((parseInt(balance.balance)/10**18).toLocaleString(undefined, {minimumFractionDigits: 8}))} <b> {balance.symbol}</b> (<span style={{fontSize:"13px", textTransform: "capitalize"}}>Withdrawable {(parseInt(balance.withdrawable)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} <b> {balance.symbol}</b></span>)
                                      </a>
                                      : null
                                    )
                                  })
                                }
                              </div>
                              : null
                            }
                          </div>
                          :
                          <div className="dropdown-earlier">
                            <button className="btn">
                              {this.state.tokens.some(symbol => symbol.symbol === "ETH") ? (this.state.tokens).length-1 : (this.state.tokens).length} Tokens <i className="bi bi-chevron-down"></i>
                            </button>
                          </div>
                        }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="summary shadow" style={{borderRadius: "4px", padding: "12px", minHeight: "237px"}}>
                          <strong style={{fontSize: "17px", marginLeft: "0px"}} className="text-hash">Tracker</strong>
                          <hr/>
                          {
                            this.state.token ?
                              <Label label="Token" value={<a href={`/token/${this.props.match.params.address}`}><img src={this.state.token_image} alt={this.state.token_name} className="image-validator" />{this.state.token_name} </a>} />
                            : null
                          }
                          {
                            this.state.collection ?
                            <Label label="Collection" value={<a href={`/collection/${this.props.match.params.address}`}><img src={this.state.collection_image} alt={this.state.collection_name} className="image-validator" />{this.state.collection_name} </a>} />
                          : null
                          }
                        </div>
                      </div>
                    </>
                    :
                    <div className="col-md-12">
                      <div className="summary shadow" style={{borderRadius: "4px", padding: "12px"}}>
                        <strong style={{fontSize: "17px", marginLeft: "0px"}} className="text-hash">Address Detail</strong>
                        <hr/>
                        <Label label="Address" value={this.props.match.params.address} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address)}}></i>} tooltip />
                        <Label label="Balance" value={<>{(parseInt(this.state.balance)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH (<span style={{fontSize:"13px"}}>Withdrawable {(parseInt(this.state.withdrawable)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} ETH</span>)</>} />
                        { 
                          (this.state.tokens.some(symbol => symbol.symbol === "ETH") && this.state.tokens.length > 1) || (!this.state.tokens.some(symbol => symbol.symbol === "ETH") && this.state.tokens.length > 0) ? 
                          <div className="dropdown-earlier">
                            <button className="btn" onClick={() => this.setState({displayDropdown: !this.state.displayDropdown})}>
                              {this.state.tokens.some(symbol => symbol.symbol === "ETH") ? (this.state.tokens).length-1 : (this.state.tokens).length} Tokens <i className="bi bi-chevron-down"></i>
                            </button>
                            {
                              this.state.displayDropdown ?
                              <div className="dropdown-menu-token overflow-custom summary" aria-labelledby="dropdownMenuButton">
                                {
                                  (this.state.tokens).map(balance => {
                                    return (
                                      balance.symbol !== "ETH" ?
                                      <a href={`/token/${balance.symbol}`} className="dropdown-item" style={{"textTransform": "uppercase"}}>
                                        {((parseInt(balance.balance)/10**18).toLocaleString(undefined, {minimumFractionDigits: 8}))} <b> {balance.symbol}</b> (<span style={{fontSize:"13px", textTransform: "capitalize"}}>Withdrawable {(parseInt(balance.withdrawable)/10**18).toLocaleString(undefined, {maximumFractionDigits: 8})} <b> {balance.symbol}</b></span>)
                                      </a>
                                      : null
                                    )
                                  })
                                }
                              </div>
                              : null
                            }
                          </div>
                          :
                          <div className="dropdown-earlier">
                            <button className="btn">
                              {this.state.tokens.some(symbol => symbol.symbol === "ETH") ? (this.state.tokens).length-1 : (this.state.tokens).length} Tokens <i className="bi bi-chevron-down"></i>
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </>
              : 
              <div className="summary shadow" style={{borderRadius: "4px", padding: "12px", minHeight: "300px"}}>
                <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "125px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
              </div>
            }
      </div>
      <div className="summary-div" style={{marginTop: "10px"}}>
        {
          this.state.checkedTxs ?
          <div className="summary shadow" style={{borderRadius: "4px", padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "6px"}} className="text-hash">Transactions</strong>
            <hr/>
            <ul className="nav nav-tabs" style={{marginBottom: "10px"}}>
              <li className="nav-item">
                {
                  this.state.looking === "out" ?
                    <button className="nav-link active">Out</button>
                  : <button className="nav-link" onClick={() => this.changeLooking("out")}>Out</button>
                }
              </li>
              <li className="nav-item">
                {
                  this.state.looking === "order" ?
                    <button className="nav-link active">Orders</button>
                  : <button className="nav-link" onClick={() => this.changeLooking("order")}>Orders</button>
                }
              </li>
              <li className="nav-item">
              {
                  this.state.looking === "inventory" ?
                    <button className="nav-link active">Inventory</button>
                  : <button className="nav-link" onClick={() => this.changeLooking("inventory")}>Inventory</button>
                }
              </li>
            </ul>
            {
              this.state.looking === "out" ?
                this.outData()
              : this.state.looking === "inventory" ?
                <div className="row">
                  {this.inventoryData()}
                </div>
              : this.orderData()
            }
            {
              this.props.dataRemaining > 0 || this.state.page > 1 ?
                <div className="container-pagination">
                {
                  this.state.page > 1 ?
                  <button className="btn" onClick={this.prevPage}><i className="bi bi-chevron-left"></i> Previous</button>
                  : <button className="btn" disabled><i className="bi bi-chevron-left"></i> Previous</button>
                }
                <b> {this.state.page} </b>
                {
                  this.props.dataRemaining > 0 ?
                  <button className="btn" onClick={this.nextPage}>Next <i className="bi bi-chevron-right"></i></button>
                  : <button className="btn" disabled>Next <i className="bi bi-chevron-right"></i></button>
                }
                </div>
              : null
            }
          </div>
          : 
          <div className="summary shadow" style={{borderRadius: "4px", padding: "12px", minHeight: "622px"}}>
            <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transfers: state.user.latestTransfer,
    nextCursor: state.user.nextCursor,
    dataRemaining: state.user.dataRemaining,
    orders: state.user.latestOrder,
    inventories: state.user.latestInventory
  }
};

const mapDispatchToProps = {
  getTransfer,
  resetStatusTransfer,
  getOrder,
  resetStatusOrder,
  getInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);