import React from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { projectName, apiURL } from '../data/API';
import Label from '../components/Label';
import { getTransfer } from '../redux/actions/getData';
import moment from 'moment';

class Token extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevCursor: [""],
      page: 1,
      looking: "out"
    }

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount() {
    document.title = `Token ${this.props.match.params.address} | ${projectName} Scan`;
    if(this.props.match.params.address.length === 42) {
      this.props.getTransfer(20, null, null, this.props.match.params.address, "ERC721")
      Axios.get(`${apiURL}/collections/${this.props.match.params.address}`)
      .then(token => {
        if(token.data){
          this.setState({
            address: token.data.address,
            name: token.data.name,
            image: token.data.icon_url,
            checked: true
          });
        }else{
          this.setState({
            checked: true,
            address: "N/A"
          })
        }
      })
    }
  }

  outData = () => {
    const arrResult = [];
    const transfers = this.props.transfers;
    var totalData = 0;
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-2 col-12"
    var colClass3 = "col-md-3 col-6"
    var colClass4 = "col-md-3 col-6"
    if(transfers.length > 0) {
      while (totalData < this.props.transfers.length) {
        arrResult.push(
          <a href={"/tx/"+transfers[totalData].transaction_id}>
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><b>{parseInt(transfers[totalData].transaction_id).toLocaleString()}</b></p>
                </div>
                <div className={colClass1}>
                  <p><b className="stopwatch">{moment(transfers[totalData].timestamp).fromNow()}</b></p>
                </div>
                <div className={colClass2}>
                  <p className="text-hash"><img src={transfers[totalData].image_url} alt={transfers[totalData].symbol} className="image-validator" /><b>{transfers[totalData].quantity}</b> {transfers[totalData].token_name}</p>
                </div>
                <div className={colClass3}>
                  <a href={"/address/"+transfers[totalData].from} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-up text-danger"></i> <i className="bi bi-person"></i> <b>{transfers[totalData].from}</b></p></a>
                </div>
                <div className={colClass4}>
                  <a href={"/address/"+transfers[totalData].to} onClick={() => this.changePath("/address")}><p className="text-hash"><i className="bi bi-arrow-return-right text-primary"></i> <i className="bi bi-person"></i> <b>{transfers[totalData].to}</b></p></a>
                </div>
              </div>
            </div>
            <hr style={{marginTop: "0px"}} />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }

  prevPage () {
    this.setState({
      page: this.state.page - 1
    });
    this.props.getTransfer(20, null, this.state.prevCursor[this.state.page - 2], this.state.address, "ERC721");
  }

  nextPage(){
    if(this.state.prevCursor.length <= this.state.page) {
      this.setState({
        prevCursor: [...this.state.prevCursor, this.props.nextCursor],
        page: this.state.page + 1
      });
    }else{
      this.setState({
        page: this.state.page + 1
      })
    }
    this.props.getTransfer(20, null, this.props.nextCursor, this.state.address, "ERC721")
  }

  render() {
    return(
      <div className="summary-div" style={{marginTop: "10px"}}>
        {
          this.state.checked ?
          <>
          <div className="summary shadow" style={{borderRadius: "4px", padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "0px"}} className="text-hash">Address Detail</strong>
            <hr/>
            <Label label="Address" value={this.state.address} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-files" onClick={() => {navigator.clipboard.writeText(this.state.address)}}></i>} tooltip />
            <Label label="Name" value={<span><img src={this.state.image} alt={this.state.name} className="image-validator"/><b>{this.state.name}</b></span>}/>
          </div>
          <div className="summary shadow" style={{borderRadius: "4px", padding: "12px"}}>
            <strong style={{fontSize: "17px", marginLeft: "0px"}} className="text-hash">Transactions</strong>
            <hr/>
            {this.outData()}
            {
              this.props.dataRemaining > 0 || this.state.page > 1 ?
                <div className="container-pagination">
                {
                  this.state.page > 1 ?
                  <button className="btn" onClick={this.prevPage}><i className="bi bi-chevron-left"></i> Previous</button>
                  : <button className="btn" disabled><i className="bi bi-chevron-left"></i> Previous</button>
                }
                <b> {this.state.page} </b>
                {
                  this.props.dataRemaining > 0 ?
                  <button className="btn" onClick={this.nextPage}>Next <i className="bi bi-chevron-right"></i></button>
                  : <button className="btn" disabled>Next <i className="bi bi-chevron-right"></i></button>
                }
                </div>
              : null
            }
          </div>
          </>
          : 
          <div className="summary shadow" style={{borderRadius: "4px", padding: "12px", minHeight: "622px"}}>
            <div className="content" style={{"textAlign": "center", margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    transfers: state.user.latestTransfer,
    nextCursor: state.user.nextCursor,
    dataRemaining: state.user.dataRemaining,
  }
};

const mapDispatchToProps = {
  getTransfer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Token);