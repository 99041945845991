import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from 'react-router-dom';

class Label extends React.Component {
  render(){
    return(
      <>
      <div className="row">
        <div className={this.props.validator ? "col-12 col-md-4" : "col-12 col-md-2"}>
          <p className="content-table-title">{this.props.label}</p>
        </div>
        <div className={this.props.validator ? "col-12 col-md-8" : "col-12 col-md-10"}>
          <p className="content-table-text">
          {
            this.props.link ? 
            <Link to={this.props.linkTo} className="content-table-link">{this.props.value}</Link>
            : this.props.value
          }
          {
            this.props.percentage ? 
              ` (${this.props.percentageValue})`
            : null
          }
          {
            this.props.tooltip ?
            <>
              <Tooltip interactive title={this.props.tooltipLabel} placement="top-start">{this.props.tooltipValue}</Tooltip>
            </>
            : null
          }
          </p>
        </div>
      </div>
      <hr style={{marginTop: "0px"}} />
      </>
    )
  }
}

export default Label;